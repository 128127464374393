<template>
	<b-modal
		dialog-class=""
		header-class="header-class-modal-doc-package"
		:title="FormMSG(207896, 'Contact info')"
		v-model="isModalOpen"
		class="modal-success"
		ok-variant="success"
		ok-only
		@ok="handleVCardModalClose"
		@show="generateVCard"
	>
		<div v-if="$has(contact)">
			<div class="full clearfix contact-name-title" style="text-align: center">
				<span class="text-center" style="font-size: 20px"> {{ contact.name }} {{ contact.firstName }} </span>
			</div>
			<div class="full clearfix content_to_center">
				<img class="qrcode-img" :src="vCardQrCodeSrc" />
			</div>
		</div>
		<template #modal-footer="{ ok }" class="content_sb_middle_end">
			<b-button variant="light" @click="ok()">{{ FormMSG(97987, 'Close') }}</b-button>
			<b-button size="small" class="push_r_10" :disabled="isContactCardDlownloadLoading" @click="handleDownloadvCard">
				<b-spinner v-if="isContactCardDlownloadLoading" small />
				{{ FormMSG(3224234, 'Download') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import QRCode from 'qrcode';
import { isNil } from '@/shared/utils';
import { mergeIfObj } from '@/shared/helpers';
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { generateVcfFile } from '@/cruds/users.crud';

export default {
	name: 'VcfContactModalComponent',
	mixins: [languageMessages, globalMixin],
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		contact: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		isModalOpen: {
			/**
			 * @return {Boolean}
			 */
			get() {
				return this.value;
			},
			/**
			 * @param {Boolean} status
			 */
			set(status) {
				this.$emit('input', status);
			}
		}
	},
	data() {
		return {
			vCardQrCodeSrc: null,
			isContactCardDlownloadLoading: false
		};
	},
	methods: {
		async handleDownloadvCard() {
			this.isContactCardDlownloadLoading = true;

			let isIos = false;

			if (this.$isIOSDevice()) {
				isIos = true;
			}

			/** @type {Object<String>} */
			// const { name, firstName, functionName } = this.contact || {};
			/** @type {String} fileName */
			// const fileName = `contact_${firstName}_${name}_${functionName}.vcf`;

			/**
			 * Must call BACK Url not FRONT Url
			 * Always need TOKEN
			 */
			// await this.downloadVcf(
			// 	process.env.VUE_APP_GQL + '/' + this.vCardUrl + '?sendMail=' + isIos,
			// 	{
			// 		'Content-Type': 'text/x-vcard',
			// 		...mergeIfObj(this.$isIOSDevice(), { 'Cache-Control': 'no-cache' }),
			// 		Authorization: 'Bearer ' + store.state.token
			// 	},
			// 	isIos,
			// 	fileName
			// ).catch((error) => {
			// 	console.error(error);
			// 	this.isContactCardDlownloadLoading = false;
			// });

			await generateVcfFile(this.contact.id, isIos).then((xid) => {
				if (!isIos) {
					const fileDownloadLink = process.env.VUE_APP_GQL + '/images/' + xid;
					window.open(fileDownloadLink, '_self', '');
				}
			});

			this.isContactCardDlownloadLoading = false;

			if (isIos) {
				this.createToastForMobile(
					this.FormMSG(20, 'Success'),
					this.FormMSG(21, 'An email was sent with an attachment "contact file" to ') + this.contact.firstName + ' ' + this.contact.name + '.',
					''
				);
			}
		},
		generateVCardData() {
			const { name, firstName, email, phone, departmentName, functionName, picture } = this.contact;

			const pictureField = !isNil(picture) && picture !== '' ? `PHOTO;JPEG:${process.env.VUE_APP_PATH_IMG_URL + picture}` : '';

			const res = [
				'BEGIN:VCARD',
				'VERSION:3.0',
				`N:${name};${firstName};;;`,
				`FN:${firstName} ${name}`,
				`EMAIL:${email}`,
				`TEL;TYPE=CELL:${phone}`,
				`ROLE:${departmentName}`,
				`TITLE:${functionName}`,
				`ORG:${store.state.myProject.titre}`,
				`NOTE: Department: ${departmentName},\n
                    Function: ${functionName},\n
                    Picture URL: ${process.env.VUE_APP_PATH_IMG_URL + picture}\n
                `,
				pictureField,
				'END:VCARD'
			];

			return res.join('\n');
		},
		generateVCard() {
			const vCardString = this.generateVCardData();
			// const blob = new Blob([vCardString], { type: 'text/vcard' });
			//
			//    // Use await to wait for the Promise to resolve
			//    const res = await getUrlFromBlob(blob);
			//    console.log({ res })

			this.vCardUrl = `vcf/${this.contact.id}`;

			// Generate QR code
			const opts = {
				errorCorrectionLevel: 'H',
				type: 'image/png',
				rendererOpts: { quality: 0.3 }
			};
			// console.log({ vCardUrl: this.vCardUrl });

			QRCode.toDataURL(vCardString, opts, (err, url) => {
				if (err) throw err;
				this.vCardQrCodeSrc = url;
			});
		},
		handleVCardModalClose() {
			this.$emit('closed', true);
		}
	}
};
</script>

<style lang="scss" scoped>
img.qrcode-img {
	display: block;
	max-width: 100%;
	@media (max-width: 600px) {
		max-width: 300px;
	}
	@media (max-width: 400px) {
		max-width: 200px;
	}
}

.download-btn-holder {
	@media (max-width: 400px) {
		display: none;
	}
}
</style>
