<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div
				:class="{
					'container-layout': $screen.width >= 992,
					'container-mobile': $screen.width < 992
				}"
				class="p-0"
			>
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
					{{ FormMSG(10, 'Contacts') }}
				</h1>
				<b-overlay :show.sync="isLoading" opacity="0.75" rounded="lg">
					<div class="tabs-project" :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
						<b-tabs card style="flex-wrap: inherit" active-nav-item-class="font-weight-bold" active-tab-class="font-weight-bold">
							<b-tab :title="FormMSG(2150, 'Per department')">
								<div :class="{ 'container-layout': $screen.width > 567, 'container-mobile': $screen.width <= 567 }">
									<b-row class="form content-contact-mobile pt-0" :class="`${$screen.width <= 567 ? '' : 'px-4'}`">
										<b-col v-if="isDeterProject === true" sm="12" md="6" lg="6" xl="6">
											<b-form-group
												:label="$screen.width > 567 ? FormMSG(817, 'Team session') : ''"
												:label-cols="$screen.width > 567 ? 4 : ''"
											>
												<v-select
													v-model="sessionOptionSelected"
													label="text"
													:options="sessionOptions"
													:placeholder="FormMSG(851, 'Select team session')"
													:reduce="(option) => option.value"
													@input="handleChangeSessionOptionSelected"
												/>
											</b-form-group>
										</b-col>
										<b-col sm="12" md="6" lg="6" xl="6">
											<b-input-group>
												<b-form-input
													type="text"
													v-model="filter"
													:placeholder="FormMSG(54, 'Type of search')"
												/><!-- @change="handleChangeFilter" -->
												<b-input-group-append class="cursor-pointer">
													<b-input-group-text class="btn-search">
														<component
															:is="getLucideIcon('Search')"
															color="#FFFFFF"
															:size="16"
															:stroke-width="2.5"
															v-if="filter.length === 0"
														/>
														<component
															:is="getLucideIcon('X')"
															color="#FFFFFF"
															:size="16"
															:stroke-width="2.5"
															@click="clearFilter"
															v-else
														/>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-col>
									</b-row>
									<ContactPerDeps :contacts="ContactsFilteredMap" />
								</div>
							</b-tab>
							<b-tab :title="FormMSG(2151, 'Alphabetical')" @click="handleAlphabeticalTab">
								<div v-if="$screen.width <= 567">
									<div class="container-mobile animated fadeIn" style="top: 95px">
										<div class="form content-contact-mobile">
											<form>
												<b-row>
													<b-col v-if="isDeterProject === true" sm="12" md="5" lg="5" xl="5">
														<b-form-group
															:label="$screen.width > 567 ? FormMSG(817, 'Team session') : ''"
															:label-cols="$screen.width > 567 ? 4 : ''"
														>
															<v-select
																v-model="sessionOptionSelected"
																label="text"
																:options="sessionOptions"
																:placeholder="FormMSG(851, 'Select team session')"
																:reduce="(option) => option.value"
																@input="handleChangeSessionOptionSelected"
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="7" lg="7" xl="7">
														<b-input-group>
															<b-form-input
																type="text"
																v-model="filter"
																:placeholder="FormMSG(54, 'Type of search')"
															/><!-- @change="handleChangeFilter" -->
															<b-input-group-append class="cursor-pointer">
																<b-input-group-text class="btn-search">
																	<component
																		:is="getLucideIcon('Search')"
																		color="#FFFFFF"
																		:size="16"
																		:stroke-width="2.5"
																		v-if="filter.length === 0"
																	/>
																	<component
																		:is="getLucideIcon('X')"
																		color="#FFFFFF"
																		:size="16"
																		:stroke-width="2.5"
																		@click="clearFilter"
																		v-else
																	/>
																</b-input-group-text>
															</b-input-group-append>
														</b-input-group>
													</b-col>
												</b-row>
												<b-row style="margin-top: 16px">
													<b-col>
														<div class="title-list">
															<h2>{{ FormMSG(25, 'Name') }}</h2>
														</div>
													</b-col>
												</b-row>
												<div v-if="ContactsFilteredMap.length === 0" class="text-center">
													<p>{{ FormMSG(74, 'No result') }}</p>
												</div>
												<div v-for="(contact, index) in ContactsFilteredMap" :key="index">
													<div v-if="index > 0" class="divider-line mt-3 mb-3" />
													<b-row :class="{ 'mt-3': index === 0 }">
														<b-col cols="2" class="text-center">
															<div v-if="contact.picture">
																<img :src="pathPicture(contact.picture)" class="img-list-contact p-0 border-0" />
															</div>
															<div v-else class="img-list-contact">
																<user color="#C4C4C4" :size="20" />
															</div>
														</b-col>
														<b-col cols="7" class="pr-0">
															<p class="name-contact mb-0">{{ contact.name }} {{ contact.firstName }}</p>
															<p>
																{{ contact.departmentName }}
																<span class="divider-dot" />
																{{ contact.customFunctionName ? contact.customFunctionName : contact.functionName }}
															</p>
														</b-col>
														<b-col cols="3" class="pr-0 pl-0">
															<div class="">
																<div class="">
																	<b-dropdown
																		no-caret
																		dropleft
																		offset="20"
																		variant="none"
																		style="border-right: 0px !important; padding-top: 0px !important"
																	>
																		<template #button-content>
																			<b-button style="border-radius: 5px" variant="light"><more-vertical /></b-button>
																		</template>
																		<b-dropdown-item-button
																			disabled
																			v-if="!contact.showMyPhoneInCrew && !contact.showMyEmailInCrew && index == 0"
																		>
																			{{ FormMSG(25621, 'No option available') }}
																		</b-dropdown-item-button>
																		<b-dropdown-item-button @click="callPhone(contact)" v-if="contact.showMyPhoneInCrew">
																			<Phone fill="#06263E" :size="16" /> &nbsp; {{ FormMSG(3561, 'Call') }}
																		</b-dropdown-item-button>

																		<b-dropdown-item-button
																			v-if="contact.showMyPhoneInCrew"
																			@click="handleSendSms(contact)"
																		>
																			<MessageCircle fill="#06263E" :size="16" /> &nbsp;
																			{{ FormMSG(77887678, 'Send Sms') }}
																		</b-dropdown-item-button>

																		<b-dropdown-item-button @click="sendEmail(contact)" v-if="contact.showMyEmailInCrew">
																			<Mail fill="#06263E" color="#E2E6EB" :size="16" />&nbsp;
																			{{ FormMSG(3562, 'Send an email') }}
																		</b-dropdown-item-button>

																		<b-dropdown-item-button
																			v-if="contact.showMyPhoneInCrew"
																			@click="showVcardModal(contact)"
																		>
																			<Copy fill="#06263E" color="#E2E6EB" :size="16" />&nbsp;
																			{{ FormMSG(3563, 'Copy contact') }}
																		</b-dropdown-item-button>
																		<!-- <b-dropdown-item-button
																			@click="moveUpMobile(contact, index)"
																			v-if="index != 0 && isDeterProject === true && haveUserAccess"
																		>
																			<ArrowBigUp fill="#06263E" color="#E2E6EB" :size="20" />&nbsp;
																			{{ FormMSG(35603, 'Move up') }}
																		</b-dropdown-item-button> -->
																	</b-dropdown>
																</div>
															</div>
														</b-col>
													</b-row>
												</div>
											</form>
										</div>
									</div>
								</div>
								<div class="form container-layout" v-else>
									<!-- bg-variant="secondary" text-variant="white" -->
									<b-card v-if="Contacts">
										<b-row v-if="$screen.width >= 992" class="pb-3">
											<b-col v-if="isDeterProject === true" sm="12" md="5" lg="5" xl="5">
												<b-form-group
													:label="$screen.width > 567 ? FormMSG(817, 'Team session') : ''"
													:label-cols="$screen.width > 567 ? 4 : ''"
												>
													<v-select
														v-model="sessionOptionSelected"
														label="text"
														:options="sessionOptions"
														:placeholder="FormMSG(851, 'Select team session')"
														:reduce="(option) => option.value"
														@input="handleChangeSessionOptionSelected"
													/>
												</b-form-group>
											</b-col>
											<b-col sm="12" md="7" lg="7" xl="7">
												<b-input-group>
													<b-form-input
														type="text"
														v-model="filter"
														:placeholder="FormMSG(54, 'Type of search')"
													/><!-- @change="handleChangeFilter" -->
													<b-input-group-append class="cursor-pointer">
														<b-input-group-text class="btn-search">
															<component
																:is="getLucideIcon('Search')"
																color="#FFFFFF"
																:size="16"
																:stroke-width="2.5"
																v-if="filter.length === 0"
															/>
															<component
																:is="getLucideIcon('X')"
																color="#FFFFFF"
																:size="16"
																:stroke-width="2.5"
																@click="clearFilter"
																v-else
															/>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-col>
										</b-row>
										<b-row>
											<b-col cols="12" xl="12">
												<!--      for expense and invoices -->
												<CardListBuilder
													v-if="$screen.width < 992"
													:items="ContactsFilteredMap"
													:fields="contactMobileFields"
													@row-clicked="rowClicked"
												>
													<template v-slot:actions="slotProps">
														<b-button
															variant="success"
															size="sm"
															class="btn bg-transparent border-0"
															:title="FormMSG(51, 'Send email')"
															:disabled="!slotProps.item.showMyEmailInCrew"
															@click="sendEmail(slotProps.item)"
														>
															<component :is="getLucideIcon(ICONS.MAIL.name)" :color="ICONS.MAIL.color" :size="20" />
														</b-button>
														<b-button
															variant="success"
															:disabled="slotProps.item.phone.length < 1 && !slotProps.item.showMyPhoneInCrew"
															size="sm"
															class="btn bg-transparent border-0"
															:title="FormMSG(52, 'Call by phone')"
															@click="callPhone(slotProps.item)"
														>
															<component :is="getLucideIcon(ICONS.PHONE.name)" :color="ICONS.PHONE.color" :size="20" />
														</b-button>
														<b-button
															variant="success"
															:disabled="slotProps.item.picture.length < 1"
															size="sm"
															:title="FormMSG(53, 'View picture')"
															@click="showPicture(slotProps.item.picture)"
															class="btn bg-transparent border-0"
														>
															<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" />
														</b-button>
														<b-button
															variant="success"
															size="sm"
															:disabled="slotProps.item.phone.length < 1 && !slotProps.item.showMyPhoneInCrew"
															:title="FormMSG(58765, 'View vCard')"
															@click="showVcardModal(slotProps.item)"
															class="btn bg-transparent border-0"
														>
															<component :is="getLucideIcon(ICONS.COPY.name)" :color="ICONS.COPY.color" :size="20" />
														</b-button>
														<!-- <b-button
															variant="success"
															size="sm"
															:title="FormMSG(587600, 'Move up')"
															class="btn bg-transparent border-0"
															v-if="slotProps.index != 0 && isDeterProject === true && haveUserAccess"
															@click="moveUp(slotProps)"
														>
															<ArrowBigUp color="#225CBD" :size="23" />
														</b-button> -->
													</template>
												</CardListBuilder>
												<b-table
													v-if="$screen.width >= 992"
													:hover="hover"
													responsive="sm"
													:items="Contacts"
													:fields="contactFields"
													:current-page="currentPage"
													:filter="filter"
													@row-clicked="rowClicked"
													:per-page="perPage"
													sticky-header="700px"
													:head-variant="hv"
													:sort-by.sync="sortBy"
													bordered
													small
												>
													<template #cell(functionName)="data">
														{{ data.item.customFunctionName ? data.item.customFunctionName : data.item.functionName }}
													</template>
													<template #cell(pict)="data">
														<b-button
															variant="success"
															size="sm"
															class="mr-1 btn bg-transparent border-0"
															:title="FormMSG(51, 'Send email')"
															@click="sendEmail(data.item)"
														>
															<component :is="getLucideIcon(ICONS.MAIL.name)" :color="ICONS.MAIL.color" :size="20" />
														</b-button>
														<b-button
															variant="success"
															:disabled="data.item.phone.length < 1"
															size="sm"
															class="mr-1 btn bg-transparent border-0"
															:title="FormMSG(52, 'Call by phone')"
															@click="callPhone(data.item)"
															v-if="data.item.showMyPhoneInCrew"
														>
															<component :is="getLucideIcon(ICONS.PHONE.name)" :color="ICONS.PHONE.color" :size="20" />
														</b-button>
														<b-button
															variant="success"
															:disabled="data.item.picture.length < 1"
															size="sm"
															:title="FormMSG(53, 'View picture')"
															@click="showPicture(data.item.picture)"
															class="btn bg-transparent border-0"
														>
															<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" />
														</b-button>
														<b-button
															variant="success"
															size="sm"
															:title="FormMSG(58765, 'View vCard')"
															@click="showVcardModal(data.item)"
															class="btn bg-transparent border-0"
														>
															<component :is="getLucideIcon(ICONS.COPY.name)" :color="ICONS.COPY.color" :size="20" />
														</b-button>
														<!-- <b-button
															variant="success"
															size="sm"
															:title="FormMSG(587600, 'Move up')"
															class="btn bg-transparent border-0"
															v-if="data.index != 0 && isDeterProject === true && haveUserAccess"
															@click="moveUp(data)"
														>
															<ArrowBigUp color="#225CBD" :size="23" />
														</b-button> -->
													</template>
												</b-table>
											</b-col>
										</b-row>
										<b-modal
											header-class="header-class-modal-doc-package"
											:title="FormMSG(20, 'Picture')"
											v-model="showContactPicture"
											class="modal-success"
											ok-variant="success"
											ok-only
											@ok="showContactPicture = false"
										>
											<b-card v-for="(oneImage, i) in contactImagePath" :key="i">
												<b-container fluid class="p-4 bg-dark">
													<b-row>
														<b-col>
															<b-img fluid :src="oneImage" />
														</b-col>
													</b-row>
												</b-container>
											</b-card>
										</b-modal>
									</b-card>
								</div>
							</b-tab>
							<VcfContactModal v-model="isVcardModalOpen" :contact="currContactVCard" @closed="currContactVCard = null" />
							<!-- <loading :active.sync="isLoading" :is-full-page="true" /> -->
						</b-tabs>
					</div>
				</b-overlay>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import { Phone, Search, Mail, User, XCircle, X, Copy, MoreVertical, MessageCircle, ArrowBigUp } from 'lucide-vue';
// import axios from 'axios'
import Loading from 'vue-loading-overlay';
import { isNil, mergeIfObj } from '@/shared/utils';
import { SwitchCustomPositionInList } from '@/cruds/users.crud';
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import VcfContactModal from '@/components/Modals/VcfContactModal';
import ContactPerDeps from './ContactPerDeps.vue';
import DeterMixin from '@/mixins/deter.mixin';

const query_Contacts = gql`
	query ($deterSession: String) {
		GetUsersOfProject(DeterSession: $deterSession) {
			id
			name
			firstName
			email
			phone
			picture
			departmentName
			departmentPrefix
			functionPrefix
			functionName
			showMyEmailInCrew
			showMyPhoneInCrew
			customFunctionName
			customPosInList
			session1A
			session1B
			session2A
			session2B
			session3A
			session3B
			session4A
			session4B
			session5A
			session5B
			session6A
			session6B
			session7A
			session7B
			session8A
			session8B
		}
	}
`;

export default {
	name: 'Contacts',

	components: {
		MessageCircle,
		Loading,
		Phone,
		Search,
		Mail,
		User,
		XCircle,
		X,
		ContactPerDeps,
		Copy,
		MoreVertical,
		VcfContactModal,
		ArrowBigUp
	},
	mixins: [languageMessages, GlobalMixin, DeterMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data: () => {
		return {
			hv: 'dark',
			erreur: {},
			curContact: {},
			contactImagePath: [],
			oneItem: '',
			Contacts: [],
			currentPage: 1,
			perPage: 0,
			warning: '',
			showContactPicture: false,
			filter: '',
			isLoading: false,
			isVcardModalOpen: false,
			currContactVCard: null,
			vCardUrl: null,
			sessionOptionSelected: '',
			sortBy: ''
		};
	},
	created() {
		this.reloadData();
	},
	computed: {
		haveUserAccess() {
			return store.canAccessUsers();
		},
		contactFields() {
			return [
				{
					key: 'functionName',
					label: this.FormMSG(34, 'Function'),
					sortable: true
				},
				{
					key: 'name',
					label: this.FormMSG(30, 'Name'),
					sortable: true
				},
				{
					key: 'firstName',
					label: this.FormMSG(31, 'First Name'),
					sortable: true
				},
				{
					key: 'phone',
					label: this.FormMSG(310, 'Phone'),
					sortable: true
				},
				{
					key: 'email',
					label: this.FormMSG(32, 'Email'),
					sortable: true,
					formatter: (v, z, root) => {
						return root.showMyEmailInCrew ? v : '';
					}
				},
				{
					key: 'departmentName',
					label: this.FormMSG(33, 'Department'),
					sortable: true
				},

				{
					key: 'pict',
					label: this.FormMSG(35, 'Actions'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		contactMobileFields() {
			const keys = ['pict'];
			return this.contactFields.filter((t) => !keys.includes(t.key));
		},
		ContactsFilteredMap() {
			var filter = this.filter.trim().toLowerCase();
			if (filter === '') return this.Contacts;

			var filteredContacts = this.Contacts.filter((s) => {
				return (
					s.name.toLowerCase().indexOf(filter) > -1 ||
					s.firstName.toLowerCase().indexOf(filter) > -1 ||
					s.email.toLowerCase().indexOf(filter) > -1 ||
					s.functionName.toLowerCase().indexOf(filter) > -1 ||
					s.departmentName.toLowerCase().indexOf(filter) > -1
				);
			});

			return filteredContacts;
		}
	},
	methods: {
		async moveUpMobile(data, index) {
			const initialIndex = index;
			let id1 = +data.id;
			let secondObjectIndex = this.Contacts[initialIndex - 1];
			let id2 = +secondObjectIndex.id;
			await SwitchCustomPositionInList(id1, id2);
			const itemToMove = this.Contacts.splice(initialIndex, 1)[0];
			this.Contacts.splice(initialIndex - 1, 0, itemToMove);
			this.$forceUpdate();
		},
		async moveUp(data) {
			const initialIndex = data.index;
			let id1 = +data.item.id;
			let secondObjectIndex = this.Contacts[initialIndex - 1];
			let id2 = +secondObjectIndex.id;
			await SwitchCustomPositionInList(id1, id2);
			const itemToMove = this.Contacts.splice(initialIndex, 1)[0];
			this.Contacts.splice(initialIndex - 1, 0, itemToMove);
			this.$forceUpdate();
		},
		async handleChangeSessionOptionSelected(payload) {
			this.filter = '';
			await this.reloadData();
		},
		clearFilter() {
			if (this.filter !== '') {
				this.filter = '';
			}
		},
		rowClicked(item) {
			this.curContact = item;
			// console.log('row item :', this.curContact)
		},
		sendEmail(item) {
			//  console.log("images:", images);
			// build path for picture
			var email = item.email;
			/*var subject = this.FormMSG(45, 'Subject')
			var emailBody = this.FormMSG(46, 'Hello') + ' ' + item.firstName + ',%0D%0A%0D%0A' + store.state.myProfile.firstName + ' ' + store.state.myProfile.name
			document.location = 'mailto:' + email + '?body=' + emailBody + '&subject=' + subject */
			document.location = `mailto:${email}`;
			//'<html><p> '  + '</p></html>' ?body=Hello1&subject=test subject1"
		},
		callPhone(item) {
			window.location.href = 'tel://' + item.phone;
		},
		handleSendSms(item) {
			window.location.href = 'sms://' + item.phone;
		},
		showPicture(image) {
			//  console.log("images:", images);
			// build path for picture
			this.contactImagePath = [];

			var fullPath = process.env.VUE_APP_GQL + '/images/' + image;
			this.contactImagePath.push(fullPath);
			this.showContactPicture = true;
		},
		/**
		 * @param {!Object} contact
		 */
		showVcardModal(contact) {
			this.currContactVCard = contact;
			if (!isNil(this.currContactVCard)) this.generateVCard();
			this.isVcardModalOpen = true;
		},
		reloadData() {
			this.isLoading = true;
			this.$apollo
				.query({
					query: query_Contacts,
					variables: {
						deterSession: this.sessionOptionSelected
					},
					// options: { fetchPolicy: 'network-only' },
					// options: { fetchPolicy: 'no-cache' },
					// force update from server
					fetchPolicy: 'network-only'
				})
				.then((result) => {
					this.Contacts = result.data.GetUsersOfProject;
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				})
				.finally(() => (this.isLoading = false));
		},
		handleAlphabeticalTab() {
			this.sortBy = 'name';
		}
	}
};
</script>
<style lang="scss">
.tabs-project {
	background-color: #ffffff;
	border-radius: 2px;
	.nav-tabs {
		flex-wrap: inherit !important;
	}
}
</style>
